import { render, staticRenderFns } from "./drama_details.vue?vue&type=template&id=45b6089c&scoped=true&"
import script from "./drama_details.vue?vue&type=script&lang=js&"
export * from "./drama_details.vue?vue&type=script&lang=js&"
import style0 from "./drama_details.vue?vue&type=style&index=0&id=45b6089c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b6089c",
  null
  
)

export default component.exports